import React from 'react';
import Footer from './Footer';
import Header from './Header';
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="text-gray-900 bg-ghostWhite">{children}</main>
      <Footer />
                {/* Cookie consent */}
          {/* Remove debug={true} for production */}
          <CookieConsent
            // debug={true}
            location="bottom"
            buttonText="Aceptar"
            declineButtonText="Rechazar"
            cookieName="gatsby-gdpr-google-analytics"
            style={{
              background: "white",
              borderTop: "1px solid ",
              borderTopColor: "lightGrey",
              justifyContent: "center",
              paddingTop: "9px",
              paddingBottom: "5px"
              // boxShadow: "0px -10px 1px lightgrey"
            }}
            buttonStyle={{
              color: "#8c8c8c",
              background: "white",
              border: "1px solid",
              borderColor: "#8c8c8c",
              fontSize: "13px",
              borderRadius: "4px",
              width: "12ch",
              display: "inline-block",
              height: "30px",
              padding: "0px"
              // marginTop: "0px"
            }}
            declineButtonStyle={{
              color: "#8c8c8c",
              background: "white",
              border: "1px solid",
              borderColor: "#8c8c8c",
              fontSize: "13px",
              borderRadius: "4px",
              width: "12ch",
              display: "inline-block",
              height: "30px",
              padding: "0px"
              // marginTop: "0px"
            }}
            contentStyle={{
              marginBottom: "auto",
              marginTop: "auto"
            }}
            expires={150}
            // enableDeclineButton
            flipButtons={true}
            // onDecline={() => {
            //   alert("nay!")
            // }}
            // onAccept={() => {
            //   alert("thank you")
            // }}
          >
            <p
              style={{
                color: "#8c8c8c",
                margin: "0px",
                fontSize: "0.85rem",
                textAlign: "center"
              }}
            >
              Utilizamos cookies propias para el correcto funcionamiento de la página web y de todos sus servicios,
y de terceros para analizar el tráfico en nuestra página web. Si continua navegando, consideramos
que acepta su uso. Puede cambiar la configuración u obtener más información.{" "}
              {/* <a href="https://...com/privacy" style={{ textDecoration: "none" }}>
                Click here
              </a> */}{" "}
            </p>
          </CookieConsent>
    </>
  );
};

export default Layout;
