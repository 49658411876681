import React, { useState } from "react"
import SonsurLogo from '../svg/SonsurLogo'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby"
import "../css/NavBar.scss"



const Navigation = styled.nav`
  height: 10vh;
  display: flex;
  background-color: #fff;
  position: sticky;
  top:0;
  justify-content: space-between;
  border-bottom: 2px solid #33333320;
  padding: 0.3vw 2vw;
  z-index: 999;

  @media (max-width: 820px) {
    justify-content: space-between;
    position: sticky;
    height: 10vh;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    padding: 0.3vw 2vw;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 5vw;
  position: absolute;
  @media (max-width: 820px) {
    display: flex;
  }
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 820px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #000F1E;
    transition: height 0.3s linear, opacity 0.4s linear 0s, visibility 0.5s linear;
    height : ${props => (props.open ? "100vh" : "0vh")};
    opacity : ${props => (props.open ? "1" : "0")};
    visibility : ${props => (props.open ? "visible" : "hidden")};
    top:10vh;
    left:0
  }
`


const Hamburger = styled.div`
  background-color: #000F1E;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #456E94;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Icons = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  position: absolute;
  @media (max-width: 820px) {
    display: flex;
    position: absolute;
    right: 0px;
    align-items: center;
    color: #456E94;
}
`

const NavItem = styled(Link)`
  text-decoration: none;
  color: #000F1E;
  font-size: 1.2rem;
  display: inline-block;
  white-space: nowrap;
  margin: 0 2.5rem;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #466f95;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: 	#466f95;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 820px) {
    padding: 20px 20px;
    font-size: 1.5rem;
    color: white;
    z-index: 6;
  }

  @media (max-width: 1024px) {
    margin: 0 1rem;
  }

`

// Deduplicate the navigation items
const navItems = [
  { href: "#about", text: "Sobre nosotros" },
  { href: "#findus", text: "Donde encontrarnos" },
  { href: "#services", text: "Servicios" }
];

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const toggleNavbar = () => setNavbarOpen(!navbarOpen);

  return (
    <Navigation>
      <Toggle navbarOpen={navbarOpen} onClick={toggleNavbar}>
        <Hamburger open={navbarOpen} />
      </Toggle>
      <figure className="__mobile__logo">
        <SonsurLogo />
      </figure>
      <Navbox open={navbarOpen}>
        {navItems.map(({ href, text }, index) => (
          <div key={index} onClick={toggleNavbar}>
            <NavItem to={href}>{text}</NavItem>
          </div>
        ))}
        <Link to="#contact">
          <button onClick={toggleNavbar} className="bg-sonsurAccent-light text-white text-center mx-10 active:bg-sonsurAccent-dark text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg hover:bg-sonsurAccent-dark outline-none focus:outline-none __hamburgerButton"
            type="submit" style={{ transition: "all 0.15s ease 0s" }}>
            Contacto
          </button>
        </Link>
      </Navbox>
      <Icons>
        <a href="tel:+34914748251" className="text-sonsurAccent-light __button_menu" aria-label="Phone">
          <FontAwesomeIcon icon={faPhoneAlt} size='lg' />
        </a>
        <a href="mailto:sonsur@sonsur.com" className="text-sonsurAccent-light __button_menu" aria-label="Email">
          <FontAwesomeIcon icon={faEnvelope} size='lg' />
        </a>
      </Icons>
    </Navigation>
  )
}

export default Navbar