import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SonsurLogo = () => {
  const data = useStaticQuery(graphql`
  query getLogo {
    images: file(name: {eq: "SonsurLogo"}) {
        name,
        childImageSharp {
          gatsbyImageData(
            quality: 100
            transformOptions: {fit: FILL, cropFocus: ATTENTION}
            placeholder: DOMINANT_COLOR
            webpOptions: {quality: 100}
            blurredOptions: {toFormat: PNG}
          )
        }
      }
    }
  `)
  return (
      <GatsbyImage image={getImage(data.images.childImageSharp)} alt={data.images.name} />
  )
}

export default SonsurLogo;

