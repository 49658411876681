import React from 'react';

const Footer = () => (
  <footer className="py-16 px-3 text-gray-800 bg-sonsurAccent-dark mx-auto">
    <div className="flex mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold text-white">Acerca de nosotros</h2>
        <p className="mt-5 text-white">Copyright {new Date().getFullYear()}. SONSUR Servicios Técnicos Electrónicos Embajadores S.L.</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold text-white">Links a sitios web oficiales</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.sony.es/" target="_blank" rel="noopener noreferrer" aria-label="Go to Sony's official website">Sony</a>
          </li>
          <li>
            <a href="http://www.panasonic.com/es/" target="_blank" rel="noopener noreferrer" aria-label="Go to Panasonic's official website">Panasonic</a>
          </li>
          <li>
            <a href="https://www.technics.com/es/" target="_blank" rel="noopener noreferrer" aria-label="Go to Technics's official website">Technics</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;